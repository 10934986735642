import React from "react";
import Project from "../Projects/Projects";

const ProjectPage = () => {
  return (
    <>
      <Project />
    </>
  );
};

export default ProjectPage;
