import React from "react";
import Services from "../Services/Services";
import ServicesExp from "../Services/ServicesExp";

const ServicePage = () => {
  return (
    <>
      <Services />
      <ServicesExp />
    </>
  );
};

export default ServicePage;
