import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../Images/B.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

const Appbar = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "/"
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const buttons = [
    {
      id: 1,
      title: "Home",
      path: "/",
    },
    {
      id: 2,
      title: "About Us",
      path: "/aboutUs",
    },
    {
      id: 3,
      title: "Services",
      path: "/services",
    },
    {
      id: 4,
      title: "Projects",
      path: "/projects",
    },
    {
      id: 5,
      title: "Contact Us",
      path: "/contactUs",
    },
  ];

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos > currentScrollPos;

    setPrevScrollPos(currentScrollPos);

    if (currentScrollPos === 0 || visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const trigger = useScrollTrigger({
    target: typeof window !== "undefined" ? window : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  useEffect(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    if (trigger) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [trigger]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navigate = useNavigate();

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
    localStorage.setItem("activeTab", newValue);
    navigate(newValue);
  };

  return (
    <AppBar
      position="static"
      elevation={isVisible || trigger ? 12 : 0}
      sx={{
        backgroundColor: isVisible || trigger ? "transparent" : "transparent",
        transition: "background 0.3s ease-in-out",
      }}
    >
      <Toolbar>
        <Grid container alignItems="center">
          <Grid
            item
            xs={2}
            sm={3}
            md={3}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            flexGrow={1}
          >
            <CardMedia
              component="img"
              src={Logo}
              sx={{
                width: { md: "30%", sm: "30%", xs: "100px" },
                ml: { md: 26, xs: 5 },
                mt: 1.3,
              }}
            />
          </Grid>
          {isMobileView ? (
            <Grid
              item
              xs={10}
              sm={7}
              md={7}
              display="flex"
              justifyContent="flex-end"
            >
              <IconButton
                edge="start"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer}
                sx={{ p: 0 }}
              >
                <MenuIcon
                  sx={{
                    width: "70px",
                    height: "50px",
                    mr: { xs: 2 },
                  }}
                />
              </IconButton>
            </Grid>
          ) : (
            <Grid
              item
              xs={10}
              sm={7}
              md={7}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flexGrow={1}
            >
              <Tabs
                value={activeTab}
                variant="scrollable"
                sx={{ cursor: "pointer" }}
              >
                {buttons.map((button) => (
                  <Tab
                    key={button.id}
                    onClick={() => handleTabChange(button.path)}
                    label={button.title}
                    sx={{
                      fontSize: "15px",
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      color: button.path === activeTab ? "#fbfcf8" : "#3384ff",
                      "&:hover": {
                        color: "#3384ff",
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Grid>
          )}
        </Grid>
      </Toolbar>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        variant="temporary"
      >
        <Box
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#131E3A",
            height: "100%",
          }}
        >
          <CardMedia
            component="img"
            src={Logo}
            sx={{
              width: { md: "50%", sm: "30%", xs: "100px" },
              ml: "auto",
              mt: 2,
              mb: 4,
              mr: "auto",
            }}
          />
          <IconButton
            edge="end"
            color="inherit"
            onClick={toggleDrawer}
            sx={{ position: "absolute", top: "0px", right: "13px" }}
          >
            <CancelIcon />
          </IconButton>
          {buttons.map((button) => (
            <div key={button.id}>
              <Button
                onClick={() => {
                  toggleDrawer();
                  handleTabChange(button.path);
                }}
                sx={{ my: 1, color: "white" }}
              >
                {button.title}
              </Button>
              <Divider sx={{ backgroundColor: "white" }} />
            </div>
          ))}
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Appbar;
