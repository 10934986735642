import React, { useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import V2 from "../Images/Aboutus/jy.jpg";
import Card1Image from "../Images/Aboutus/P3/plexus-abstract-object-07-removebg-preview.png";
import Card2Image from "../Images/Aboutus/P3/plexus-abstract-object-08-removebg-preview.png";
import Card3Image from "../Images/Aboutus/P3/plexus-abstract-object-16-removebg-preview.png";

const AboutUs = () => {
  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallaxEffect = () => {
      if (parallaxRef.current) {
        const yOffset = window.pageYOffset;
        parallaxRef.current.style.backgroundPositionY = `${yOffset * 0.5}px`;
      }
    };

    window.addEventListener("scroll", parallaxEffect);

    return () => {
      window.removeEventListener("scroll", parallaxEffect);
    };
  }, []);

  const cardsContent = [
    {
      id: 1,
      title: "Our History",
      description:
        "Except to obtain some advantage from it? But who has any right to find fault with a consequences.",
      image: Card1Image,
    },
    {
      id: 2,
      title: "Our Mission",
      description:
        "Except to obtain some advantage from it? But who has any right to find fault with a consequences.",
      image: Card2Image,
    },
    {
      id: 3,
      title: "Our Vision",
      description:
        "Except to obtain some advantage from it? But who has any right to find fault with a consequences.",
      image: Card3Image,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Box
        ref={parallaxRef}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: { md: "100%", sm: "100%", xs: "1470px" },
          backgroundImage: `url(${V2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "brightness(50%)",
          zIndex: -1,
          mt: -11,
          transition: "background-position 0.1s ease-in-out",
        }}
      />

      <Box
        sx={{
          // flex: "1",
          textAlign: "center",
          color: "#ffffff",
          zIndex: 1,
          padding: "20px",
        }}
      >
        <Typography variant="h3" gutterBottom sx={{ mt: 7, mb: 10 }}>
          WELCOME TO AUTOROBOS
        </Typography>
        <Box sx={{ mt: 4, paddingLeft: "20px", paddingRight: "20px" }}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph align="left">
                Since 2012, AUTOROBOS has established itself as a trusted name
                in cutting-edge technology services. With a decade of experience
                in healthcare IT and billing, our team of experts knows how to
                deliver results swiftly and efficiently. We offer comprehensive
                solutions to organizations of all sizes and domains, ensuring
                they receive top-notch services. Our offerings include digital
                marketing, automation, machine learning, artificial
                intelligence, and Internet of Things (IoT). Currently, our
                advanced software solutions are empowering nearly one million
                satisfied professionals, including robotics company, digital
                Agencies, and Software experts across 80,000+ facilities
                nationwide.
              </Typography>
              <Button variant="contained" color="primary">
                Request a Consultation
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              {cardsContent.map((card) => (
                <Card
                  key={card.id}
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#ffffff",
                    maxWidth: { md: "80%", sm: "80%", xs: "100%" },
                    position: "relative",
                    marginBottom: "20px",
                    ml: { md: "auto", sm: "auto", xs: "auto" },
                  }}
                >
                  <CardContent
                    sx={{
                      backgroundImage: `url(${card.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "8px",
                      padding: "20px",
                      position: "relative",
                      zIndex: 1,
                      height: "100%",
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      {card.title}
                    </Typography>
                    <Typography variant="body2">{card.description}</Typography>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
