// Import React and Material UI components
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Grid,
  Avatar,
} from "@mui/material";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";

const typographyStyle = {
  color: "#394273",
  fontWeight: "bold",
};

const Text = [
  {
    id: 1,
    backgroundColor: "#f2f5ff",
    color: "#466efe",
    title: "Quality Support",
    desc: "Learn content by interacting anything expert lesson a video.",
  },
  {
    id: 2,
    backgroundColor: "#fde8ff",
    color: "#f36fff",
    title: "Fastest AI Machine",
    desc: "Practice what you realistic test great questions lorem.",
  },
  {
    id: 3,
    backgroundColor: "#ffefe8",
    color: "#ff9061",
    title: "Great Pricing",
    desc: "Review your practice & learn how to improve your skill.",
  },
];

function Quality() {
  return (
    <>
      <Box
        sx={{
          minHeight: { md: "40vh", xs: "100vh" },
          py: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            boxShadow: 20,
            height: { md: 270, xs: "100vh" },
            width: "74%",

            background: "white",
            position: "absolute",
            zIndex: 4,
            borderRadius: "30px",
            "@media (max-width: 600px)": {
              height: 700,
              mt: -13,
              ml: 1,
              position: "absolute",
              zIndex: 4,
            },
          }}
        >
          <CardContent>
            <Grid container spacing={2}>
              {Text.map((text, index) => (
                <Grid item xs={12} sm={4} key={text.id}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: text.backgroundColor,
                        color: text.color,
                      }}
                    >
                      <DownloadDoneIcon />
                    </Avatar>
                  </Box>
                  <Typography
                    style={typographyStyle}
                    variant="h6"
                    sx={{
                      mt: 2,
                      fontFamily: "revert-layer",
                      textAlign: "center",
                    }}
                  >
                    {text.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mt: 2,
                      color: "#9babb9",
                      fontFamily: "revert-layer",
                      border: "0px solid black",
                      width: "70%",
                      textAlign: "center",
                      ml: 6,
                    }}
                  >
                    {text.desc}
                  </Typography>
                  {index !== Text.length - 1 && (
                    <Divider
                      orientation="vertical"
                      sx={{
                        mt: -13,
                        height: "30%",
                        position: "absolute",
                        ml: 45,
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default Quality;
