import React from "react";
import { Box } from "@mui/material";

const ContactMap = () => {
  return (
    <Box>
      <iframe
        title="Google Map"
        width="100%"
        height="500"
        loading="lazy"
        allowFullScreen
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15554.77341636042!2d72.98817628311073!3d31.549700728722194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391c923c2af7c09d%3A0x80a3b786928df1bf!2sFaisalabad%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2sus!4v1644991360279!5m2!1sen!2sus"
        style={{
          transition: "filter 0.8s",
          filter: "brightness(30%)",
          borderBlockColor: "#ffffff",
          borderBlockEndStyle: "initial",
        }}
        onMouseOver={(e) => (e.target.style.filter = "brightness(100%)")}
        onMouseOut={(e) => (e.target.style.filter = "brightness(30%)")}
      ></iframe>
    </Box>
  );
};

export default ContactMap;
