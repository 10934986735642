import React from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import b1 from "../Images/Services/exp/Ai/ils_20.png";
import b2 from "../Images/Services/exp/Ai/img_13.jpg";
import b3 from "../Images/Services/exp/Ai/shape_48.png";

const ServicesExp = () => {
  const serviceName = "Machine Learning";
  const words = serviceName.split(" ");

  return (
    <div style={{ backgroundColor: "#fcfdff" }}>
      <Grid
        container
        style={{ backgroundColor: "#f6fcff", minHeight: "300px" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" align="center" fontWeight={"bold"}>
            {words.map((word, index) => (
              <span key={index}>
                {index === 1 ? (
                  <span
                    style={{
                      textDecoration: "underline",
                      textDecorationColor: "purple",
                      color: "purple",
                    }}
                  >
                    {word}
                  </span>
                ) : (
                  word
                )}
                {index < words.length - 1 && " "}
              </span>
            ))}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "flex-end" }}
        >
          <img
            src={b1}
            alt="Service"
            style={{ width: "70%", height: "auto", objectFit: "cover" }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "450px",
            backgroundImage: `url(${b3})`,
            backgroundSize: "cover",
            backgroundPosition: "flex-end",
          }}
        >
          <Card
            sx={{ border: "1px solid black", width: "50%", height: "30vh" }}
          >
            <CardContent>
              <Typography variant="h6">Technologies</Typography>
              <Typography variant="body1">
                - Technology 1<br />
                - Technology 2<br />
                - Technology 3<br />
                - Technology 4<br />
                - Technology 5<br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8} sx={{ mt: 5, ml: { md: 0, xs: 2 } }}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{ width: { md: "80%", xs: "90%" } }}
          >
            Delivering the insights you to help business growth
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ width: { md: "80%", xs: "90%" } }}
          >
            AUTOROBOS is an automation company that automates your tasks and
            workflows. We provide automation solutions that reduce your manual
            work, errors, and costs. We also provide automation consulting,
            training, and support services to help you implement and maintain
            your automation systems. Whether you want to automate your
            manufacturing, logistics, or administration, we can help you achieve
            it with our automation solutions.
            <br />
            - Point 1<br />
            - Point 2<br />
            - Point 3<br />
            - Point 4<br />
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: "flex", justifyContent: "center" }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={6.5}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={b2}
            alt="Specific Service"
            style={{ maxWidth: "100%", height: "auto", padding: "5px" }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 7 }}>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={8}>
          <Grid item xs={12}>
            <Typography variant="h6">Our Goals</Typography>
            <Typography variant="body1">
              - Goal 1<br />
              - Goal 2<br />
              - Goal 3<br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServicesExp;
