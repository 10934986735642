import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  Snackbar,
  Button,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import "ui-neumorphism/dist/index.css";
import bg from "../Images/Contact/6.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactInformation = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    description: "",
  });

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = snackbarState;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone" && !isNaN(value)) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:5000/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Email sent successfully");
        setSnackbarState({ ...snackbarState, open: true });
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }

    const requiredFields = ["name", "email", "phone", "subject", "description"];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map(
        (field) => field.charAt(0).toUpperCase() + field.slice(1)
      );
      alert(
        `Please fill in all required fields: ${missingFieldNames.join(", ")}`
      );
      return;
    }

    if (!formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      setSnackbarState({ ...snackbarState, open: true });
      return;
    }

    console.log(formData);
  };

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        py: 4,
      }}
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{ textAlign: "left", pl: { sm: 4 }, m: 10 }}
        >
          <Typography variant="h4" gutterBottom>
            <Box display="flex" alignItems="center">
              <Divider
                orientation="vertical"
                sx={{
                  ml: 0,
                  mr: 1,
                  height: "2rem",
                  alignSelf: "stretch",
                  color: "white",
                  border: "2px solid black",
                }}
              />
              Contact with AUTOROBS
            </Box>
          </Typography>
          <Typography variant="body1" paragraph sx={{ mb: 2 }}>
            We would be happy to answer any questions and explore how our
            services can support your business. If you tell us a bit about your
            interests, we’ll make sure we get you the best contact person on our
            end.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            If you’d like to talk to someone now, give us a call at
            <strong>(+92) 321 786 4079</strong>.
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  variant="filled"
                  label="Name *"
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="filled"
                  label="Email *"
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  variant="filled"
                  label="Phone *"
                  fullWidth
                  inputMode="numeric"
                  pattern="[0-9]*"
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  variant="filled"
                  label="Subject *"
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  variant="filled"
                  label="Description *"
                  fullWidth
                  multiline
                  rows={4}
                  InputProps={{
                    style: { resize: "both" },
                  }}
                  sx={{ mb: 2 }}
                />
              </Grid>
            </Grid>

            <Box sx={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
              {/* <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message="Form is Submitted"
                key={vertical + horizontal}
              /> */}
            </Box>
          </form>
          <ToastContainer
            autoClose={5000}
            // toastStyle={{ backgroundColor: "#4527a4", color: "whitesmoke" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactInformation;
