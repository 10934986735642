import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Container,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { initialServicesData, Content } from "./servicesData";
import ServicesExp from "./ServicesExp";

const mainDiv = {
  position: "relative",
  backgroundColor: "#1a1a1a",
  minHeight: "100vh",
  width: "100%",
};
const main = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  mt: { md: 0, xs: 4 },
};
const Acc1 = {
  width: "100%",
  marginBottom: "10px",
  p: "10px",
  border: "1px solid #81b0ed",
  borderRadius: "10px",
  backgroundColor: "#1a1a1a",
};
const box1 = {
  display: "flex",
  justifyContent: "flex-start",
};
const button = {
  color: "whitesmoke",
  border: "1px solid gray",
  mt: 2,
  "&:hover": {
    color: "white",
    backgroundColor: "#81b0ed",
    border: "1px solid white",
  },
};
const main2 = {
  position: "relative",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const box2 = {
  height: "80%",
  maxWidth: "80%",
  maxHeight: "80%",
  position: "relative",
};
const div1 = {
  position: "absolute",
  bottom: 14,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
};
const div2 = {
  position: "relative",
  backgroundColor: "#f1f4f7",
  height: "102.2vh",
  overflow: "hidden",
};
const containerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
};

const ServiceComponent = () => {
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const [servicesData, setServicesData] = useState(initialServicesData);
  const [isDetailViewActive, setIsDetailViewActive] = useState(false);
  const [activeServiceIndex, setActiveServiceIndex] = useState(null);
  const [expanded, setExpanded] = useState(`panel0`);
  const [isPanelExpanding, setIsPanelExpanding] = useState(false);
  const detailViewRef = useRef(null);

  useEffect(() => {
    if (!isDetailViewActive) {
      const interval = setInterval(() => {
        setCurrentServiceIndex((prevIndex) =>
          prevIndex === servicesData.length - 1 ? 0 : prevIndex + 1
        );
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [isDetailViewActive, servicesData.length]);

  const handleButtonClick = (index) => {
    setActiveServiceIndex(index);
    setIsDetailViewActive(true);
    setExpanded(`panel${index}`);

    setTimeout(() => {
      if (detailViewRef.current) {
        detailViewRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  // const handleCloseButtonClick = () => {
  //   setIsDetailViewActive(false);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  const handleCloseButtonClick = () => {
    setIsDetailViewActive(false);

    const scrollToTop = () => {
      const currentPosition = window.pageYOffset;
      if (currentPosition > 0) {
        window.scrollTo(0, currentPosition - 50); // Adjust the value (50) to control the scroll speed
        setTimeout(scrollToTop, 10); // Adjust the timeout for smoother animation
      }
    };

    scrollToTop();
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (newExpanded && !isPanelExpanding) {
      setIsPanelExpanding(true);
      setExpanded(panel);
      setTimeout(() => {
        setIsPanelExpanding(false);
      }, 300);
    }
  };
  return (
    <>
      <div style={mainDiv}>
        <Grid container spacing={0} style={{ height: "100%" }}>
          <Grid item xs={12} sm={6} sx={main}>
            <Container>
              {initialServicesData.map((service, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  sx={Acc1}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "whitesmoke" }} />}
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", color: "whitesmoke" }}
                    >
                      {service.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography align="left" sx={{ color: "whitesmoke" }}>
                      {service.content}
                    </Typography>
                    <Box sx={box1}>
                      <Button
                        onClick={() => handleButtonClick(index)}
                        variant="outlined"
                        sx={button}
                      >
                        View More
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Container>
          </Grid>
          <Grid item xs={12} sm={8} md={6} sx={main2}>
            <Box sx={box2}>
              <img
                src={servicesData[currentServiceIndex].image}
                alt={servicesData[currentServiceIndex].title}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
          </Grid>
        </Grid>

        <div style={div1}>
          <Button
            style={{
              marginRight: 10,
              color: "black",
            }}
            onClick={() =>
              setCurrentServiceIndex((prevIndex) =>
                prevIndex === 0 ? servicesData.length - 1 : prevIndex - 1
              )
            }
          >
            <ArrowCircleLeftIcon />
          </Button>
          <Button
            style={{
              color: "black",
            }}
            onClick={() =>
              setCurrentServiceIndex((prevIndex) =>
                prevIndex === servicesData.length - 1 ? 0 : prevIndex + 1
              )
            }
          >
            <ArrowCircleRightIcon />
          </Button>
        </div>
      </div>
      {isDetailViewActive && (
        <div style={div2} ref={detailViewRef}>
          <Container maxWidth="md" style={containerStyle}>
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {Content[activeServiceIndex].title}
            </Typography>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              {Content[activeServiceIndex].subtitle}
            </Typography>
            <Typography align="left">
              {Content[activeServiceIndex].focus}
            </Typography>
            <div style={{ textAlign: "left" }}>
              {Content[activeServiceIndex].services.map((service, index) => (
                <Typography key={index} align="left">
                  <b>{activeServiceIndex + 1} : </b> {service}
                </Typography>
              ))}
              <Button variant="outlined" onClick={handleCloseButtonClick}>
                Close
              </Button>
            </div>
          </Container>
        </div>
      )}
      <ServicesExp />
    </>
  );
};

export default ServiceComponent;
