import React, { useRef } from "react";
import Banner from "../Component/Banner/Banner";
import Services from "../Component/Services";
import BackgroundVideo from "../Layout/BackgroundVideo";
import QualityCustom from "../Component/Quality";
import QAComponent from "../Component/Q&A";
import ScrollAnimationComponent from "../Animations/ScrollAnimationComponent/ScrollAnimationComponent";
import {
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  Zoom,
  ZoomIn,
  ZoomOut,
  Animator,
} from "react-scroll-motion";

const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
const FadeUp = batch(Fade(), Move(), Sticky());

const Home = () => {
  const analysisRef = useRef();
  const reviewRef = useRef();
  const solutionsRef = useRef();
  return (
    <>
      <Banner />
      <Services />
      <ScrollAnimationComponent />
      {/* <BackgroundVideo components={[analysisRef, reviewRef, solutionsRef]} /> */}
      <QualityCustom />
      <QAComponent />
    </>
  );
};

export default Home;

{
  /* <ScrollContainer>
<ScrollPage page={0}>
  <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
    <Banner />
  </Animator>
</ScrollPage>
</ScrollContainer>

<ScrollContainer>
<ScrollPage page={1}>
  <Animator animation={ZoomInScrollOut}>
    <Services />
  </Animator>
</ScrollPage>
</ScrollContainer> */
}
