import React from "react";
import AboutUs from "../AboutUs/AboutUs";
import DifferentiationSection from "../AboutUs/DifferentiationSection";
import AboutMission from "../AboutUs/AboutMission";
import AboutVision from "../AboutUs/AboutVision";
import AboutValue from "../AboutUs/AboutValue";
import AboutOffer from "../AboutUs/AboutOffer";
import AboutWhy from "../AboutUs/AboutWhy";

const AboutPage = () => {
  return (
    <>
      <AboutUs />
      <DifferentiationSection />
      <AboutMission />
      <AboutVision />
      <AboutWhy />
      {/* <AboutValue /> */}
      <AboutOffer />
    </>
  );
};

export default AboutPage;
